import { DownloadBanner } from 'components/Banner/DownloadBanner/DownloadBanner';
import { MobileNavigationClient } from 'components/PublicNavigation/MobileNavigation/MobileNavigationClient';
import { NavbarAuthGate } from 'components/PublicNavigation/NavbarAuthGate';
import { NavbarContainer } from 'components/PublicNavigation/NavbarContainer';
import { NavbarItemsClient } from 'components/PublicNavigation/NavbarItemsClient';
import { NavbarSRTitle } from 'components/PublicNavigation/NavbarSRTitle';
import { NavbarSearch } from 'components/PublicNavigation/NavbarSearch';
import { NavbarStickyVariant } from 'components/PublicNavigation/NavbarStickyVariant';
import { TruckOptionsModal } from 'components/TruckOptions/TruckOptionsModal';
import { Logo } from 'components/common/Logo/Logo';
import { PanelFlex } from 'components/layout/Panel/PanelFlex';
import { PanelItem } from 'components/layout/Panel/PanelItem';
import { makeStyles } from 'lib/makeStyles';
import { memo } from 'react';

type PublicNavigationProps = {
  background?: string;
};

export const NAVIGATION_HEIGHT_CLASSNAME = 'h-18 md:h-20';
export const useStyles = makeStyles<PublicNavigationProps>()({
  root: ({ className }) => [NAVIGATION_HEIGHT_CLASSNAME, className],
  inner: ({ background = 'neutralBackground' }) => [
    'w-full',
    'fixed z-public-navigation',
    `bg-${background}`,
    'dark:bg-transparent',
    'tmsticky:bg-neutralBackgroundSoft tmsticky:shadow-md',
    'duration-200 lg:duration-300 transition-all'
  ],
  container: 'px-3 md:px-6',
  contentPanelItem: ['h-16 md:h-20'],
  navbarPanelItemWrapper: ['lg:block', 'hidden', 'relative'],
  buttonsContainer: ['flex', 'pl-4', 'space-x-4'],
  splitter: ['h-5 w-0.5', 'bg-primaryBorder', 'mx-3'],
  logo: 'h-10',
  mobileNav: 'lg:hidden'
});

export const PublicNavigation = memo((props: PublicNavigationProps) => {
  const styles = useStyles(props);

  return (
    <header>
      <nav>
        <NavbarStickyVariant />
        <NavbarSRTitle />
        <div className={styles.root}>
          <div className={styles.inner}>
            <NavbarContainer>
              <PanelItem
                spacing="XL"
                justify="between"
                fullWidth
                paddingX="LG"
                className={styles.contentPanelItem}>
                <PanelItem spacing="XL" justify="start" paddingY={false}>
                  <Logo className={styles.logo} />
                  <div className={styles.mobileNav}>
                    <DownloadBanner />
                  </div>
                </PanelItem>
                <NavbarSearch />
                <div className={styles.mobileNav}>
                  <MobileNavigationClient />
                </div>
                <div
                  id="navbarContainer"
                  className={styles.navbarPanelItemWrapper}
                  suppressHydrationWarning>
                  <PanelFlex spacing="2XL">
                    <NavbarItemsClient />
                    <NavbarAuthGate />
                  </PanelFlex>
                  <TruckOptionsModal />
                </div>
              </PanelItem>
            </NavbarContainer>
          </div>
        </div>
      </nav>
    </header>
  );
});

PublicNavigation.displayName = 'PublicNavigation';
